<template>
  <div class="min-h-screen">
    <main class="py-5">


 

      <div class="mx-auto">
        
        <div>
          <form @submit.prevent="createCoin()">
            <div class="shadow-sm overflow-hidden sm:rounded-md">
              <div class="p-10 bg-white dark:bg-gray-900 ">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12 sm:col-span-12">

           <div>
            <h1 class="text-4xl text-gray-900 dark:text-white pb-5 font-bold  mb-5 ">Submit new coin</h1>
          </div>

                    <label
                      for="network"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Token Contract Address</label
                    >
                    <input
                      placeholder="0xb8c77482e45f1f44de1745f52c74426c631bdd52"
                      v-model="coin.contract"
                      type="text"
                      name="contract"
                      id="contract"
                      autocomplete="on"
                      class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  

                         
                    <label
                      for="network"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Type</label
                    >
                    <select
                      required
                      v-model="coin.type"
                      id="type"
                      name="type"
                      autocomplete="off"
                       class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    >
                      <option>Launched</option>
                      <option>Presale</option>
                    </select>
             

                  
                    <label
                      for="network"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Network / Chain</label
                    >
                    <select
                      required
                      v-model="coin.network"
                      id="network"
                      name="network"
                      autocomplete="off"
                       class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    >
                      <option>BSC</option>
                      <option>ETH</option>
                    </select>
             

                   
                    <label
                      for="Name"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Token Name</label
                    >
                    <input
                      placeholder="Binance Coin"
                      v-model="coin.fullname"
                      required
                      type="text"
                      name="fullname"
                      id="fullname"
                      autocomplete="on"
                      class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
             
                    <label
                      for="symbol"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Symbol</label
                    >
                    <input
                      placeholder="BNB"
                      v-model="coin.symbol"
                      required
                      type="text"
                      name="symbol"
                      id="symbol"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  
                    <label
                      for="image"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Logo URL</label
                    >
                    <input
                      placeholder="https://..."
                      v-model="coin.image"
                      required
                      type="text"
                      name="image"
                      id="image"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  
                    <label
                      for="launched"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Launch Date</label
                    >
                    <input
                      placeholder="Launched"
                      v-model="coin.launched"
                      required
                      type="date"
                      name="launched"
                      id="launched"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                
                    <label
                      for="telegram"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Website</label
                    >
                    <input
                      placeholder="https://..."
                      v-model="coin.website"
                      type="text"
                      name="website"
                      id="website"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  
                    <label
                      for="telegram"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Telegram</label
                    >
                    <input
                      placeholder="https://..."
                      v-model="coin.telegram"
                      type="text"
                      name="telegram"
                      id="telegram"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                   
                    <label
                      for="telegram"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Twitter</label
                    >
                    <input
                      placeholder="https://..."
                      v-model="coin.twitter"
                      type="text"
                      name="twitter"
                      id="twitter"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  
                    <label
                      for="discord"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Discord</label
                    >
                    <input
                      placeholder="https://..."
                      v-model="coin.discord"
                      type="text"
                      name="discord"
                      id="discord"
                      autocomplete="on"
                        class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  
                    <label
                      for="description"
                      class="block  font-medium text-gray-700 dark:text-gray-300"
                      >Description</label
                    >
                    <textarea
                      placeholder="Describe your coin here..."
                      v-model="coin.description"
                      required
                      type="text"
                      name="description"
                      id="description"
                      maxlength="1200"
                      rows="6"
                       class=" 
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      
                       focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                      mb-5
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-white dark:bg-gray-900 text-right sm:px-6">
                <button
                  type="submit"
                  class="
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    shadow
                    
                    font-medium
                    rounded-md
                    text-white
                    bg-green-600
                    hover:bg-green-700
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 
                  "
                >
                  Create Coin 
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import moment from "moment";
export default {  

  data() {
    return {
      coin: {
        contract: "",
        type: "",
        network: "",
        fullname: "",
        symbol: "",
        image: "",
        approved: 0,
        votes: 0,
        launched: "",
        description: "",
        website: "",
        telegram: "",
        twitter: "",
        discord: "",
        status: "Listed",
        added: moment().format('Y-MM-D'),
      },
    };
  },
  methods: {
    createCoin() {
      this.$store.dispatch("ADD_COIN", { coin: this.coin, vm: this });
    },
   moment: function () {
    moment().format("Y-MM-D"); 
    },
  },
};
</script>

<style></style>
